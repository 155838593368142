import type { DataObjectDefinitionFieldType } from 'o365.modules.DataObject.Fields.ts';
import type { ItemModel } from 'o365.modules.DataObject.Types.ts';

import API from 'o365.modules.data.api.ts';
import { ref } from 'vue';

interface ITreeOjectOptions {
    id: string|number,
    configs?: Array<Config>,
    whereClause?: string,
    value?: any
}

const trees = new Map<string|number, { value: TreeObject }>();

export default function getTreeObject<T extends ItemModel = ItemModel>(pOptions: ITreeOjectOptions) {
    if (!trees.has(pOptions.id)) {
        trees.set(pOptions.id, ref(new TreeObject<T>(pOptions)));
    }
    return trees.get(pOptions.id)?.value as TreeObject<T> | undefined;
}

function getTreeObjectById(pId: string | number) {
    return trees.get(pId)?.value;
}

export class TreeObject<T extends ItemModel = ItemModel> {
    private _currentNode?: TreeNode<T>;
    id: string|number;
    node: TreeNode<T>;
    current?: TreeNode<T>;
    configs: Config[] = [];
    whereClause?: string;
    nodeClick?: (pNode?: TreeNode<T>) => void;
    nodeExpand?: (pNode?: TreeNode<T>) => void;
    data?: T[];
    idPathMap: Set<number> = new Set();
    search?: string;
    loading: boolean = false;

    set currentNode(pNode) {
        this._currentNode = pNode;
        if (this.nodeClick) {
            this.nodeClick.call(this, pNode);
        }
    }
    get currentNode() {
        return this._currentNode;
    }

    constructor(pOptions: ITreeOjectOptions) {
        this.id = pOptions.id;
        if (pOptions.configs)
            pOptions.configs.forEach(conf => {
                this.configs.push(new Config(conf));
            });

        this.whereClause = pOptions.whereClause;

        this.node = new TreeNode<T>(this.id, null, null);
    }

    /* updateNodesFromData(pData){
         this.data = pData;
         this.node = new TreeNode(this.id,null,null);
         this.node.addChildren(this.data.filter);
 
     }*/
    resetNode() {
        this.node = new TreeNode<T>(this.id, null, null);
    }

    async reloadWhereClause(pWhereClause?: string) {
        if (this.whereClause !== pWhereClause) {
            this.whereClause = pWhereClause;
            this.node = new TreeNode<T>(this.id, null, null);
            this.loading = true;
            await this.node.initChildren();
            this.loading = false;
        }
    }

    async reloadSearchString(pSearchString?: string) {
        if (this.search !== pSearchString) {
            this.search = pSearchString;
            this.node = new TreeNode<T>(this.id, null, null);
            this.loading = true;
            await this.node.initChildren();
            this.loading = false;
        }
    }

    clearAllNodes() {

    }

    async retrieve(options: any) {
        const vConfig = this.configs[0];
        let vOptions = {
            viewName: vConfig.viewName,
            fields: vConfig.fields,
            whereClause: this.whereClause
        }
        if (options) {
            vOptions = Object.assign(vOptions, options);
        }
        const data = await request<T>(vOptions);
        return data;
    }

    async doSearch(pSearch: string) {
        this.resetNode();
        if (!pSearch) {
            return this.node.initChildren();
        }
        const vSearchField = this.configs[0].searchField;
        const vIdPathField = this.configs[0].keyPathField;
        this.loading = true;
        return this.retrieve({
            filterString: `[${vSearchField}] LIKE '%${pSearch}%'`,
            fields: [{ name: vIdPathField }]
        }).then(pData => {
            if (pData.length === 0) { return; }
            return this.retrieve({
                whereClause: this.getPathInString(pData)
            }).then(pRows => {
                this.data = pRows;
                this.addChildNodes(this.node);
            });
        }).finally(() => {
            this.loading = false;
        });
    }

    async setAsRoot(id: string) {
        this.resetNode();
        const keyField = this.configs[0].keyField;
        const idPathField = this.configs[0].keyPathField;
        this.loading = true;        
        return this.retrieve({
            filterString: `[${keyField}] = '${id}'`,
            fields: [{ name: idPathField }]
        }).then(pData => {
            if (pData.length === 0) { return; }
            return this.retrieve({
                whereClause: this.getPathInString(pData)
            }).then(pRows => {
                this.data = pRows;
                this.addChildNodes(this.node);
            });
        }).finally(() => {
            this.loading = false;
        });
    }

    getPathInString(pData: T[]) {
        this.idPathMap.clear();
        const vIdField = this.configs[0].keyField;
        const vIdPathField = this.configs[0].keyPathField;
        if (!vIdPathField) { return; }
        pData.forEach(path => {
            path[vIdPathField].split('/').forEach((id: string) => {
                if (id && !this.idPathMap.has(+id)) {
                    this.idPathMap.add(+id);
                }
            })
        });
        return this.whereClause
            ? `[${vIdField}] IN (${Array.from(this.idPathMap).join()}) AND ${this.whereClause}`
            : `[${vIdField}] IN (${Array.from(this.idPathMap).join()})`;
        
    }

    addChildNodes(pNode: TreeNode) {
        if (this.data == null) { return; }
        const vIdField = this.configs[0].keyField;
        const vParentIdField = this.configs[0].parentField;
        const vData = pNode.parent ? this.data.filter(x => x[vParentIdField] == pNode.item?.[vIdField]) : this.data.filter(x => x[vParentIdField] == null);
        if (vData.length){
            pNode.addChildren(vData);
            pNode.children.forEach(child => {
                this.addChildNodes(child);
            })
        }
    }

    /** Returns the selected rows ids */
    getSelectedRows() {
        const selectedRows: any[] = [];
        const idField = this.configs[0].keyField
        const addSelectedRows = (node: TreeNode, isRoot = false) => {
            if (node.selected || isRoot || node.hasSelected) {
                if (node.item && node.selected) {
                    selectedRows.push(node.item[idField]);
                }
                if (node.expanded) {
                    node.children.forEach(detailNode => {
                        addSelectedRows(detailNode);
                    });
                }
            }
        };
        addSelectedRows(this.node, true);
        return selectedRows;
    }
}


export class TreeNode<T extends ItemModel = ItemModel> {
    item?: T;
    parent: TreeNode<T> | null = null;
    treeObjectId: string | number;
    private _children: TreeNode<T>[] = [];
    private level: number = 0;
    private _expandable: boolean = true;
    expanded: boolean = false;
    loading: boolean = false;
    _selected: boolean = false;
    filterString?: string;
    key?: string;

    get children() {
        if (this.expanded) {
            return this._children;
        }
        return [];
    }

    get hasChildren() {
        return this._children.length > 0;
    }

    get hasSelected(): boolean {
        return this.children.some(node => node.selected || node.hasSelected);
    }

    get title() {
        if (this.config && this.config.title) {
            return this.config.title.call(this, this.item!);
        }
        return null;
    }

    get config() {
        const treeObject = getTreeObjectById(this.treeObjectId);
        if (treeObject == null) { 
            return undefined; 
        } else if (treeObject.configs.length === 1) { 
            return treeObject.configs[0];
        } else if (this.level <= 1) { 
            return treeObject.configs[0];
        } else {
            return treeObject.configs[this.level - 1];
        }
    }

    get parentConfig() {
        const treeObject = getTreeObjectById(this.treeObjectId);
        if (treeObject == null) {
            return undefined;
        } else if (treeObject.configs.length === 1) {
            return treeObject.configs[0];
        } else {
            return treeObject.configs[this.level - 1];
        }
    }

    get id() {
        if (this.item && this.config) {
            return this.item[this.config.keyField];
        }

        return new Date();
    }

    get hasNodes() {
        if (!this._expandable)  {
            return false;
        } else if (this.item && this._getCountFromItem() !== undefined) {
            return this._getCountFromItem();
        } else if (this.item && this.config && this.config.nodesCountField) {
            return this.item[this.config.nodesCountField];
        }

        return null;
    }

    get value() {
        if (this.item && this.config && this.parentConfig) {
            return this.item[this.parentConfig.keyField];
        }
        return null;
    }

    get count() {
        if (this.item == null) {
            return null;
        } else if (this._getCountFromItem() !== undefined) {
            return this._getCountFromItem();
        } else if (this.config && this.config.nodesCountField) {
            return this.item[this.config.nodesCountField];
        }
    }

    get selected() { return this._selected; }

    set selected(value) {
        this._selected = value;
        this.children.forEach(node => node.selected = value);
    }

    constructor(treeObjectId: string|number, pItem: T | null = null, pParent: TreeNode<T> | null = null, children: T[] | null = null) {
        this.treeObjectId = treeObjectId;
        if (pItem) {
            this.item = pItem;
        }
        this.parent = pParent;
        if (this.parent) {
            this.selected = this.parent.selected;
            this.level += this.parent.level > 0 ? this.parent.level + 1 : 1;
        }
        if (this.item) {
            this.key = this.id ?? JSON.stringify(this.item);
            if (this.item["expandable"] !== undefined) {
                this._expandable = this.item["expandable"];
            }
        }
        if (children) {
            this.addChildren(children);
        }
    }

    nodeClick(pNode: TreeNode<T>) {
        const treeObject = getTreeObjectById(this.treeObjectId);
        if (treeObject == null) { return; }
        treeObject.currentNode = pNode;
    }

    nodeExpand(pNode: TreeNode<T>) {
        const treeObject = getTreeObjectById(this.treeObjectId);

        if (this.config?.multiSelect) {
            this.children.forEach(node => {
                node.selected = this.selected;
            });
        }

        if (treeObject?.nodeExpand) {
            treeObject.nodeExpand.call(this, pNode);
        }
    }

    addChildren(pData: T[], pClear: boolean = true) {
        if (pClear) this._children = [];
        pData.forEach((item) => {
            this._children.push(new TreeNode<T>(this.treeObjectId, item, this));
        });
        this.loading = false;
        this.expanded = true;
    }

    async initChildren(pOptions: {
        filterString?: string
    } | null = null) {
        if (this._children.length > 0) {
            this.expanded = !this.expanded;
            return this._children;
        }
        if (this.item && !this.hasNodes) { return; }
        if (!this.config) { return; }
        this.loading = true;

        if (pOptions && pOptions.filterString) {
            this.filterString = pOptions.filterString;
        }
        const data = await request<T>({
            viewName: this.config.viewName,
            whereClause: getWhereClause(this),
            fields: this.config.fields,
            filterString: this.filterString
        });
        this.addChildren(data);
        return this._children;
    }

    resetChildren() {
        this._children = [];
    }

    reset() {
        this.loading = false;
        this.expanded = false;
    }

    /** Check for both 'count' and 'Count' */
    private _getCountFromItem() {
        if (this.item == null) {
            return undefined;
        } else if (this.item['count'] !== undefined) {
            return this.item['count'];
        } else if (this.item['Count'] !== undefined) {
            return this.item['Count'];
        }
    }
}

function getWhereClause<T extends ItemModel = ItemModel>(pNode: TreeNode<T>) {
    const treeObject = getTreeObjectById(pNode.treeObjectId);
    if (treeObject == null) { return undefined; }
    const vWhereClause: string[] = [];

    if (treeObject?.whereClause) {
        vWhereClause.push(treeObject.whereClause);
    }
    if (treeObject?.search) {
        vWhereClause.push(treeObject.search);
    }
    if (pNode.config) {
        if (pNode.item) {
            vWhereClause.push(`[${pNode.config.parentField}] = '${pNode.item[pNode.config.keyField]}'`);
        } else {
            vWhereClause.push(`[${pNode.config.parentField}] IS NULL`);
        }
    }
    return vWhereClause.join(' AND ');
}

class Config<T extends ItemModel = ItemModel> {
    // level:number = null;
    viewName: string;
    parentField: string;
    keyField: string;
    searchField?: string;
    keyPathField: string | null = null;
    fields: DataObjectDefinitionFieldType[] = [];
    nodesCountField: string;
    multiSelect = false;
    title?: (pItem: T) => string;

    constructor(pOptions: Config) {
        //this.level = pOptions.level;
        this.viewName = pOptions.viewName;
        this.parentField = pOptions.parentField;
        this.keyField = pOptions.keyField;
        this.nodesCountField = pOptions.nodesCountField;
        this.title = pOptions.title;
        if (pOptions.keyPathField) {
            this.keyPathField = pOptions.keyPathField;
        }
        if (pOptions.searchField) {
            this.searchField = pOptions.searchField;
        }
        if (pOptions.multiSelect) {
            this.multiSelect = pOptions.multiSelect;
        }

        if (pOptions['fields']) pOptions['fields'].forEach(field => {
            this.fields.push({
                name: field.name,
                groupByOrder: field.groupByOrder,
                groupByAggregate: field.groupByAggregate,
                sortOrder: field.sortOrder,
                sortDirection: field.sortDirection,
            });
        });

        if (!pOptions['fields']) {
            this.fields = [
                { name: this.keyField, sortOrder: 1, sortDirection: 'asc', groupByOrder: 1 },
                { name: this.parentField, groupByOrder: 1 },
                { name: this.keyField, alias: "Count", groupByAggregate: "COUNT" }
            ]
        }


        if (!this.nodesCountField)
            this.fields.push({
                name: this.keyField ?? this.parentField,
                alias: "Count",
                groupByAggregate: "COUNT"

            })

        if (this.nodesCountField && !this.fields.find(x => x.name === this.nodesCountField)) {
            this.fields.push({ name: this.nodesCountField });
        }
    }
}

async function request<T extends ItemModel = ItemModel>(pOptions: {
    viewName: string,
    whereClause?: string,
    filterString?: string,
    fields: DataObjectDefinitionFieldType[]
}) {
    const data = await API.requestPost(`/nt/api/data/${pOptions.viewName}`, JSON.stringify({
        operation: 'retrieve',
        viewName: pOptions.viewName,
        whereClause: pOptions.whereClause,
        filterString: pOptions.filterString,
        fields: pOptions.fields
    }));
    return data as T[];
}